import React from 'react';

const Blog = () =>{
    return(
        <div>
            TBD: Working on this feature, soon to come...
        </div>
    )
}

export default Blog
